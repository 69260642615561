export default function Tmp() {

    const submitGET = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch(form['url'], {
            method: 'GET',
            headers: {
                'Authorization': form['token']                
            }
        }).then(res => res.json())
            .then(j => console.log(j))
    }

    const submitPOST = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch(form['url'], {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "email":process.env.REACT_APP_EMAIL,
                "password":process.env.REACT_APP_PASSWORD
            }),
        }).then(res => res.json())
            .then(j => console.log(j))
    }

    return(
        <div style={{
            margin: 'auto'
        }}>
            <form onSubmit={submitGET}>
                GET
                <label>URL</label>
                <input type="text" name="url"/>
                <label>Token</label>
                <input type="text" name="token"/>
                <button>Submit</button>
            </form>
            <hr />
            <form onSubmit={submitPOST}>
                POST
                <label>URL</label>
                <input type="text" name="url"/>
                <button>Submit</button>
            </form>
        </div>
        
    )
}