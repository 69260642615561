import { logApiState } from "../Logger";
import { getJWTToken } from "./ConnectWithApi";
import { ROOT_PATH } from "./CONSTANTS";
import { makeGetQuery } from "./Utils";

export const questionPath = '/data/v1/question';
export const customQuestionGETPath = '/getCustomQuestionSet';
export const reqQuestionGETPath = '/getReq';

/**
 * Translates queryObj from QuizGenerator to a valid query string.
 */
export const generateQueryString = (queryObj) => {

    let route, query;

    if(queryObj.type === 'custom'){
        route = ROOT_PATH + questionPath + customQuestionGETPath;
        query = queryObj.years.join('-')
                + 'V'
                + queryObj.sections.join('-')
                + 'V'
                + queryObj.number;
    } else if(queryObj.type === 'featured-years'){
        route = ROOT_PATH + questionPath + reqQuestionGETPath;
        query = 'e' + queryObj.year + 'Vl80Vo0';

    } else if (queryObj.type === 'featured-years'){
        route = ROOT_PATH + questionPath + customQuestionGETPath;
    } else {
        logApiState('error: invalid type of queryObj received.')
        return '/error';
    }

    return (route + 'QUERYEQUALS' + query);

}

export const getQuestionData = async (query) => {
    return await getJWTToken()
        .then(token => makeGetQuery(query, token))
        .catch(error => {throw new Error('error in getQuestionData: ' + error)})
}

export const getFigureData = async(query) => {
    return await getJWTToken()
        .then(token => makeGetQuery(query, token))
}
