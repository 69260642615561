import { createContext, useRef, useState } from "react";

export const AppContext = createContext(null);

export default function AppContextProvider({children}){

  const [signedIn, setSignedIn] = useState(false);

  const values = {
    signedIn, setSignedIn
  }

  return (
    <AppContext.Provider value={values}>
        {children}
    </AppContext.Provider>
  )

}