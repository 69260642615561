/* Root path of host server. */

// export const ROOT_PATH = 'https://159.223.65.170:443';
export const ROOT_PATH = 'https://fequiz.swanhtetoo.com';
export const FEATURED_QUESTION_NUM = 10;   // default question number for quick quiz (featured questions).
export const CHECKED_COLOR = '#AEFFFF';
export const UNCHECKED_COLOR = '#fff';
export const DEFAULT_QUIZ_QUERY_YEAR = '1';
//export const DEFAULT_CREDENTIALS = {
//    "email":process.env.REACT_APP_EMAIL,
//    "password":process.env.REACT_APP_PASSWORD
//};
export const DEFAULT_CREDENTIALS = {
  "email": "anon@demo.fe",
  "password": "password"
};
