import { useState } from "react";
import '../../styles/Utils.css';

import Button from "./Button";
import { login, signup } from "../../apiConnectors/ConnectWithApi";

/**
 * Component to use for sign in and sign up.
 */
export default function SigninupScreen({ signin }){

    // Whether to signin (true) or singup (false)
    const [isSignIn, setIsSignIn] = useState(signin);


    const submitSigninupForm = (e) => {
        e.preventDefault();

        const data = {
            email: e.target['signup-email'].value,
            password: e.target['signup-password'].value
        }

        console.log(data);

        if(isSignIn)
            login(data);
        else 
            signup(data)
    }

    return(
        <form 
            className="_SigninupScreen"
            id="signinupform"
            onSubmit={submitSigninupForm}
        >
            
            <h2>{isSignIn ? 'Welcome back, ' : 'Join us, '}buddy.</h2>

            { !isSignIn &&
                <label>
                    Name
                    <input type="text" name="signup-name"/>
                </label>
            }
            <label>
                Email
                <input type="email" name="signup-email"/>
            </label>
            <label>
                Password
                <input type="password" name="signup-password"/>
            </label>
            { !isSignIn &&
                <div className="signupSelects">
                    <select name="signup-age" defaultValue={'Age'}>
                        {/* <option value="" disabled selected>Age</option> */}
                        <option value="0">below 18</option>
                        <option value="1">18 to 25</option>
                        <option value="2">over 25</option>
                    </select>
                    <select name="signup-gender" defaultValue={'Gender'}>
                        {/* <option value="" disabled selected>Gender</option> */}
                        <option value="0">Male</option>
                        <option value="1">Female</option>
                        <option value="2">Other</option>
                    </select>
                    <select name="signup-edu" defaultValue={'Education'}>
                        {/* <option value="" disabled>Education</option> */}
                        <option value="0">Highschool</option>
                        <option value="1">Undergrad</option>
                        <option value="2">Postgrad</option>
                    </select>
                </div>
            }
            <div className="signinup-btns">
                <Button
                    label={'Google'}
                    bgColor={'#fff'}
                />
                {
                    isSignIn
                    ? <Button
                    label={'Log In'}
                    bgColor={'#FBFF4E'} />
                    : <Button
                    label={'Confirm'}
                    bgColor={'#FBFF4E'} />
                }
            </div>
            {
                isSignIn
                ? <div className="signinup-switch">
                    <p>Not a member yet?</p>
                    <a data-testId='signup' onClick={() => setIsSignIn(n => !n)}>Sign Up</a>
                </div>
                : <div className="signinup-switch">
                    <p>Already have an account?</p>
                    <a data-testId='signin' onClick={() => setIsSignIn(n => !n)}>Sign In</a>
                </div>
            }
        </form>
    )
}