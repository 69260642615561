import SigninupScreen from '../../Utils/SigninupScreen';
import '../../../styles/Home.css';
import ComingSoon from '../../Utils/Beta/ComingSoon';


/**
 * Signup 'page' in the home page.
 * Data: Home_data.js
 * Children: [SignupScreen, SigninScreen]
 * Props:
 *      - signin    whether signin (true) or signup (false)
 */
export default function SignupPage({signin}){

    return(
        <div style={{position: 'relative'}}>
            <ComingSoon />
            <div className='_SignupPage' id='page_signup' data-testId='signupPage'>
                <aside>
                    Let's make our dreams happen.
                </aside>
                <SigninupScreen signin={signin} />
            </div>
        </div>
        
    )
}