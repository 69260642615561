import Logo from "./Logo";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContextProvider";
import Button from "./Button";
import { generateQueryString, getQuestionData } from "../../apiConnectors/FetchQuestionData";
import BugReport from "./Beta/BugReport";

/**
 * Main NavBar for the app.
 * Children: [Logo]
 * Props:
 *      type    -   type of nav: main | quiz.
 */
export default function Navbar ({type, reportTarget}){

    
    /** For beta version START */
    const [report, setReport] = useState(false);

 
    /** For beta version END */

    const { signedIn } = useContext(AppContext);
    const practice = signedIn ? '/dashboard' : '/quizgen';

    let sectionContent;

    if(type === 'main'){
        sectionContent = 
            <>
                <a href="#page_home">Home</a>
                <a href="#page_about">About</a>
                <a href="#page_signup">Sign Up</a>
                <Button
                    label={<Link className="practice-btn" to={practice}>Practice</Link>}
                    bgColor={'#EDF058'}
                    navStyle={true}
                />
            </>
    } else if (type === 'quiz'){
        sectionContent = 
            <>
                {/* <Button
                    label={<Link to='/signup'>Sign Up</Link>}
                    bgColor={'#AEFFFF'}
                    bigNavStyle={true}
                    extraClass={'quizgenNav-signupBtn'}
                /> */}
                {/* <Button
                    label={<Link to='/signin'>Sign In</Link>}
                    bgColor={'#FBFF4E'}
                    bigNavStyle={true}
                /> */}
                <Button
                    label={'Report'}
                    bgColor={'#FBFF4E'}
                    bigNavStyle={true}
                    onClickFn={() => setReport(true)}
                />
            </>
    }

    return (
        <>
        {report  
        ?   <BugReport reportTarget={reportTarget} reportSetter={setReport}/>
        :   <nav className="_Navbar" data-testId='navBar'>
                <Logo type={type}/>
                <section>
                    {sectionContent}
                </section>
                {/* <button onClick={testqueryFunc}>QUERY</button> */}
            </nav>
        }
        </>
    )
}

async function testqueryFunc() {
    let reqData, queryString, result;

    let zeroQuestions = {}

    const sectionArray = [1,2,3,4,5,6,7,8,9]
    // const examArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]
    const examArray = [10]

    sectionArray.forEach(
        section => {
            examArray.forEach(
                async (exam) => {
                    reqData = {type: 'custom', sections: [section], years: [exam], number: 1}
                    queryString = generateQueryString(reqData);
                    queryString = queryString.replaceAll('QUERYEQUALS', '?q=');
                    result = await getQuestionData(queryString);
                    if(result.length > 0) return;

                    // console.log(`SectionID: ${section}, ExamID: ${exam} has no question.`)
                    if(!zeroQuestions[section]){
                        zeroQuestions[section] = [];
                    }
                    zeroQuestions[section].push(exam)

                    if(section === 9){
                        Object.keys(zeroQuestions).forEach(
                            key => zeroQuestions[key] = zeroQuestions[key].sort()
                        )
                        console.log('ZEROQUESTIONS:')
                        console.log(zeroQuestions);
                    }
                    

                }
            )
        }
    )


}