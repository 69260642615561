import '../../../styles/Home.css';


/*
* About section of the home page.
*/
export default function About(){
    let i = 0; // index for keys.
    return (
        <article className='_About' id='page_about' data-testId='aboutPage'>
            <h1>About</h1>
            <ul>
                {/* <li>Welcome to <span className='highlight-1'>THE FE PROJECT</span>, a place for hard working learners of Computer Science.</li> */}
                <li><span className='highlight-1'>The FE Project</span> aims to provide <span className='highlight-1'>free, high quality</span> learning resources.</li>
                <li>This is <span className='highlight-3'>beta</span> version of our first product, <span className='highlight-3'>FE Quiz App</span>.</li>
                {/* <li>This app can be used to practice <span className='highlight-3'>ten year's</span> worth of old questions from ITPEC FE exams.</li> */}
                <li>Currently, <span className='highlight-3'>1,520</span> questions from twenty exams are available for practice.</li>
                <li><span className='highlight-2'>Thank you so much</span> for trying this out and please fell free to <span className='highlight-2'>report bugs</span> by clicking 'Report' button in the quiz app. Your help is very much appreciated.</li>
                {/* <li>For any suggestion/question, please <a href={`mailto:${emailAddress}`}>click here to email us</a>. </li> */}
            </ul>
        </article>
    )
}