import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CHECKED_COLOR, UNCHECKED_COLOR, DEFAULT_QUIZ_QUERY_YEAR } from "../../apiConnectors/CONSTANTS";
import { getQuestionData } from "../../apiConnectors/FetchQuestionData";
import { generateQueryString } from "../../apiConnectors/FetchQuestionData";
import { AppContext } from "../../AppContextProvider";

import arrowImg from '../../assets/images/arrowImg.png';
import shortArrowImg from '../../assets/images/shortArrowImg.png';
import starImg from '../../assets/images/starImg.png';
import { logAppError, logAppTest } from "../../Logger";

import Question from "../SubComponents/Quiz/Question";
import ResultScreen from "../SubComponents/Quiz/ResultScreen";
import Button from "../Utils/Button";
import LoadingScreen from "../Utils/LoadingScreen";
import Navbar from "../Utils/Navbar";

let storedData = localStorage.getItem('sampleQuestionSet');
let sampleData = [];
if(storedData){
    sampleData = JSON.parse(storedData).filter((e,i) => i <= 2);
}

export default function Quiz(){

    let { query } = useParams();

    if(!query) 
        query = generateQueryString({
            type: 'featured-years',
            year: DEFAULT_QUIZ_QUERY_YEAR
        })

    const navigate = useNavigate();

    const { isSignedIn } = useContext(AppContext);

    const [questionData, setQuestionData] = useState(null);         // Array of question objects

    const [currentQuestion, setCurrentQuestion] = useState(null);   // question object for current question

    const [screenState, setScreenState] = useState('quiz')  // MUST BE: `quiz` | `result` | `review`.

    // pointer for current question:
    const pointer = useRef(0);

    /** Initializing `questionData` using params from querystring. */
    useEffect(() => {
        
        let queryString = query.replaceAll('&', '/').replaceAll('QUERYEQUALS', '?q=')
            // queries question data
            getQuestionData(queryString)
                .then(result => {
                    // if empty, show error page.
                    if(result.length === 0){
                        throw new Error(' No questions ')
                    }
                    setQuestionData(result);
                }).catch(err => {
                    logAppError('[Quiz.jsx] Cannot fetch questionData for query: ' + queryString)
                    logAppError(err)
                    navigate('/error/503')
                })
    }, [query]);

    // Starting with the first question:
    useEffect(() => {
        if(questionData){
            setCurrentQuestion(questionData[pointer.current]);
        }
    }, [questionData]);

    /**
     * When user selects one of the answers,
     */
    const selectAns = inputNode => {
        clearCheckedBtns();
        // style checked label:
        inputNode.labels[0].style.backgroundColor = CHECKED_COLOR;
        inputNode.checked = true;
    }

    /**
     * When user clicks Previous/Next Button,
     *  -   Save select answer
     *  -   Move to Previous/Next question
     *  -   Clear mcq-answer inputs
     *  -   Show previously-saved user_answer for the question
     */

    const changeQuestions = (newIndex) => {
        if(screenState === 'quiz') saveUserAns(currentQuestion);
        setCurrentQuestion(questionData[newIndex]);    
        clearCheckedBtns();
        if(screenState === 'quiz') showUserAnsIfExists(questionData[newIndex], selectAns);
    }

    // TMP
    const backToMenu = () => {
        navigate('/quizgen');
    }

    // When user clicks 'Review' Button from resultScreen
    const startReviewing = () => {
        if(!questionData){
            logAppError('Question data becomes empty on review.')
            throw new Error('Question Data Allocated to Be Empty.');
        }
        pointer.current = 0;
        setCurrentQuestion(questionData[0])
        setScreenState('review')
    }


    // When user clicks 'Submit' on the last question
    const submitAnswers = () => {
        saveUserAns(currentQuestion);
        logAppTest(questionData); // this is to be used in testing.
        setScreenState('result')
    }

    const toggleStar = (e) => {
        currentQuestion['star'] = !currentQuestion['star'];
        if(currentQuestion['star']){
            e.target.classList.remove('star-img-false')
            e.target.classList.add('star-img-true')
        } else {
            e.target.classList.remove('star-img-true')
            e.target.classList.add('star-img-false')
        }
    }

    

    return(
        <div className="_Quiz">
            <Navbar type={'quiz'} reportTarget={'question_'+ (currentQuestion ? currentQuestion.id : '')}/>

        {// Show Question and Controls after data is loaded and it is not result screen.
        (screenState !== 'result' && questionData && currentQuestion)
            ?
            <main>
                {/* 1. Header bar above questions */}
                <section data-testId='question-header'>
                    {/* 1a. Section Name: */}
                    <p>{'< '+currentQuestion.section.name+' >'}</p>
                    {/* 1b. Progress Bar: */}
                    <div className="quiz-progress">
                        <div className="quiz-bar">
                            <div style={{ 
                                width: `calc(( ${(pointer.current+1)/questionData.length} )*100%)`
                            }}></div>
                        </div>
                        <p>{pointer.current+1}/{questionData.length}</p>
                    </div>
                </section>
                
                {/* 2. Question */}
                <Question data={currentQuestion} isReview={screenState === 'review'}/>

                {/* 3. Controls, that include -
                    *
                    *  I.   In `quiz` screenState:
                    *        Previous Button (if not the first question)
                    *        Answer Choices (a-b-c-d)
                    *        Next Button OR Submit Button
                    * 
                    * II.  In `review` screenState:
                    *        Back to Menu Button
                    *        Toggle Star Button
                    *        Previous Button (if not the first question)
                    *        Next Button (if not the last question)
                */}
                <div className="mcq-controls">

                    {// Back to Menu Button [for review screen]
                    screenState === 'review' &&   
                        <>
                            <Button
                                label={'Back to Menu'}
                                bgColor={'#eee'}
                                onClickFn={backToMenu}
                            />
                            {// Star/Unstar questions [when signed in]
                            isSignedIn &&
                                <img className={`star-img-${currentQuestion['star']}`} src={starImg} alt="Star Question" onClick={toggleStar} />}
                        </>}

                    {// Previous Button [for both screens]  
                    (questionData.length > 1 && pointer.current > 0) &&     
                        <button className="btn-previous" onClick={() => changeQuestions(--pointer.current)}
                        style={screenState === 'review' ? {
                                marginLeft: 'auto',
                                marginRight: '30px'
                            } : {}}
                        data-testId='testid-previous-btn'>
                            <img src={arrowImg} alt="Previous Question" />
                            <img src={shortArrowImg} alt="Previous Question" />
                        </button>}

                    {// MCQ Answers [for quiz screen]
                    (screenState === 'quiz') &&
                        <form id='mcq-form' onSubmit={e => e.preventDefault()}>
                            {['a', 'b', 'c', 'd'].map((ans, i) => 
                                <label key={i}> {ans} 
                                <input onChange={(e) => selectAns(e.target)} type="radio" name="answer" value={ans} /> </label>
                            )}
                        </form>}

                    {// if not last question, Next Button [for both screens]
                    !(pointer.current >= questionData.length-1)   
                        ?   <button className="btn-next" onClick={() => changeQuestions(++pointer.current)} data-testId='testid-next-btn'>
                                <img src={arrowImg} alt="Next Question" />
                                <img src={shortArrowImg} alt="Next Question" />
                            </button>

                        :  (// if last question, Submit Button [for quiz screen]
                            screenState === 'quiz'  &&
                            <div className="submit-btn" data-testId='testid-submit-btn'>
                                <Button
                                    label={'Submit'}
                                    bgColor={'#FBFF4E'}
                                    onClickFn={submitAnswers}
                                />
                            </div>)}
                </div>
            </main>

            // if not `quiz` or `review`, show either Result Screen OR Loading Screen
            : (screenState === 'result'
                ? <ResultScreen
                    data={questionData}
                    reviewFn={startReviewing}
                    menuFn={backToMenu}
                />

                : <LoadingScreen/>
            )
        }
        </div>
    )
}

function clearCheckedBtns () {
    // all answer labels:
    const ansLabels = document.getElementById('mcq-form').children;
    // style 'unchecked' all labels:
    [...ansLabels].forEach(i => {
       i.style.backgroundColor = UNCHECKED_COLOR
       i.children[0].checked = false;
   });
}

// Shows if user had chosen an answer for the current question.
function showUserAnsIfExists(questionObj, selectAns) {
   let userPrevAns = questionObj['user_answer'];
   if(userPrevAns){
       selectAns(
           [...document.getElementById('mcq-form').children]
           .filter(
               l => l.children[0].value === userPrevAns
           )[0].children[0]
       );
   }
}

function saveUserAns (question) {
   // Save user's answer. If not answered, save ''. 
   let userAnswer = document.getElementById('mcq-form')['answer'].value;
   if(!userAnswer) userAnswer = '';
   question['user_answer'] = userAnswer;
}