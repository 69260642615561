import { Link } from "react-router-dom";

import homeVisual from '../../assets/images/homeVisual.png'
import curvedArrow from '../../assets/images/curvedArrow.png'
import blob1 from '../../assets/images/blob1.png'
import blob2 from '../../assets/images/blob2.png'
import '../../styles/Home.css';

import Logo from '../Utils/Logo';
import Navbar from '../Utils/Navbar';
import About from '../SubComponents/Home/About';
import SignupPage from '../SubComponents/Home/SignupPage';
import { useContext } from "react";
import { AppContext } from "../../AppContextProvider";
import Button from "../Utils/Button";

// Home Page
export default function Home(){

    const { signedIn } = useContext(AppContext);

    const practice = signedIn ? '/dashboard' : '/quizgen'

    /*
    * Three pages: home page, about page and signup page.
    */
    return(
        <div className="_Home">
            <div className="home-page" id="page_home">
                <div className='home-header'>
                    <hr />
                    <Logo type={'main'} />
                </div>
                <div className="home-hero-container">
                    <div className='home-hero'>
                        <img src={homeVisual} alt=""  data-testId='homeVisual'/>
                        <ul data-testId='homeDisplayText'>
                            <li>Practice.</li>
                            <li>Makes.</li>
                            <li>Everything.</li>
                        </ul>
                        {/* for mobile screen */}
                        <div className="blob1-img-wrapper">
                            <img src={blob1} alt=""/>
                        </div>
                    </div>
                </div>
                <div className='home-nav' data-testId='homeNav'>
                    <Button
                        label={<a href="#page_about">About</a>}
                        bgColor={'#58F08C'}
                    />
                    <Button
                        label={<a href="#page_signup">Sign Up</a>}
                        bgColor={'#58F0F0'}
                    />
                    <Button
                        label={<Link to={practice}>Practice</Link>}
                        bgColor={'#EDF058'}
                    />
                </div>
            </div>
            
            <Navbar type={'main'}/>
            <About />
            <SignupPage />

            <footer>
                The FE Project © 2023. All Rights Reserved.
            </footer>

            {/* Decorations */}
            <div className="curvedArrow-img-wrapper">
                <img src={curvedArrow} alt=""/>
            </div>

            <div className="blob2-img-wrapper">
                <img src={blob2} alt=""/>
            </div>
        </div>
    );
}