import { Link } from "react-router-dom";

/**
 * Logo of the app.
 * Different versions of logo are for different types:
 *  1. 'main'   -   the root logo
 *  2.  'quiz'  -   quiz app logo
 */
export default function Logo ({ type }){
    return (
        <div className='_Logo' data-testId='logo'>
            <Link to='/' style ={{
                margin:'0px',
                width: 'fit-content',
                textTransform: 'uppercase',
                fontFamily: 'var(--font-logo)',
                fontSize: 'var(--logo-font-size)',
                paddingLeft: '10px',
                paddingRight: '10px'
            }}>
            the <span>FE</span> project
            {
                type === 'quiz'
                && <span className="logo-quiz">quiz</span>
            }
            </Link>
        </div>
    )
}