import correctImg from '../../../assets/images/correctImg.png'
import incorrectImg from '../../../assets/images/incorrectImg.png'

import '../../../styles/Quiz.css'
import { getJWTToken } from "../../../apiConnectors/ConnectWithApi";
import { makeGetQuery } from "../../../apiConnectors/Utils";
import { useState, useEffect } from "react";
import { ROOT_PATH } from "../../../apiConnectors/CONSTANTS";
import { getFigureData } from '../../../apiConnectors/FetchQuestionData';

// builds a queryString to query figures.
const buildFigQuery = (id) => ROOT_PATH + `/data/v1/figure/getAll/${id}?q=string`

export default function Question({data, isReview}) {

    const [figures, setFigures] = useState(null);

    // if the question has figures, query figure data.
    useEffect(() => {
        if(data.hasFigures){
            getFigureData(buildFigQuery(data.id)).then(
                img => {
                    setFigures(img)
                }
                )
        } else {
            setFigures(null)
        }
    }, [data])

    // shows answer with alphabet (a | b | c | d) and text.
    const buildAnsTag = (char) => {
        return (
            <div className="review-anstag">
                <span>({char})</span>
                {clean(data[`ans${char.toUpperCase()}`])}
            </div>
        )
    }

    return (
        <div className="_Question" data-testId="question">
            <p className="question">{clean(data.question)}</p>
            { figures && <>
                {
                    figures.map((fig, i) => 
                    <img key={i} src={fig} alt="Figure for Question. (Apologies, transcription currently unavailable)." />)
                }
            </> }
            {
            // If not review screen, shows all four answer choices.
                !isReview
                ?
                (// Shows full answers if they are not just 'a)', 'b)' etc, which are used in figure-only mcq answers.
                data.ansA !== 'a)' &&
                <ul className="answers">
                    <li className="answer">{clean(data.ansA)}</li>
                    <li className="answer">{clean(data.ansB)}</li>
                    <li className="answer">{clean(data.ansC)}</li>
                    <li className="answer">{clean(data.ansD)}</li>
                </ul>)
                :
            // If review screen, shows correct answer and user_answer.
                <table className="answers-review">
                    <tbody>
                    {/* CORRECT ANSWER */}
                    <tr>
                        <td>{buildAnsTag(data.correctAns)}</td>
                        <td><img src={correctImg} alt="Correct" /></td>
                    </tr>
                    {/* USER's ANSWER (if not correct) */}
                    {
                        (data.correctAns !== data.user_answer) &&
                        <tr>
                            <td>{
                                data.user_answer
                                ? buildAnsTag(data.user_answer)
                                : 'No Answer'
                            }</td>
                            <td><img src={incorrectImg} alt="Incorrect" /></td>
                        </tr>
                    }
                    </tbody>
                </table>
            }
        </div>
    )
}

/**
 * To clean non-utf8 data.
 */
function clean(str) {

    return str
                .replaceAll("Â", "")
                .replaceAll("�", "")
                .replaceAll("áµ—Ê°", "th")
                .replaceAll("â‹…", ".")
                .replaceAll('"¾', "¯")
                .replaceAll("â€™", "'")
                .replaceAll("â€œ", '"')
                .replaceAll("â€“", '-')
                .replaceAll("â€", '"')

}
