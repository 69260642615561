import Button from "../Button"
import emailjs from '@emailjs/browser';
import { logAppError, logBugReport } from "../../../Logger";

export default function BugReport({ reportTarget, reportSetter }){

    const h3Style = {
        margin: '0'
    }

    const modalStyle ={
        position: 'absolute',
        top: '0',
        width: '100vw',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(20px)',
        zIndex: '15'
    }

    const modalFormStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '30vw',
        minWidth: '300px',
        margin: '30px 0'
    }

    const formLabelStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px'
    }

    const textLabelStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    }

    const formInputStyle = {
        fontSize: '16px',
        color: '#222',
        fontFamily: 'var(--font-text)',
        minWidth: '25px',
        minHeight: '25px'
    }

    // Scrolls to top
    window.scrollTo(0, 0);
    // Disables scrolling
    document.getElementsByTagName('body')[0].classList.add('stop-scrolling')

    const cancelModal = () => {
        // Enable scrolling
        document.getElementsByTagName('body')[0].classList.remove('stop-scrolling')
        reportSetter(false);
    }

    const submitModal = (e) => {

        // Enable scrolling
        document.getElementsByTagName('body')[0].classList.remove('stop-scrolling')

        const form = document.getElementById('bug-report-form')

        const issues = ['text', 'figure', 'button', 'other'].filter(n => form[n].checked);

        let reportData = {
            timestamp: new Date(),
            target: reportTarget,
            issues: issues,
            details: form['details'].value
        }

        logBugReport(reportData);
        sendEmail(reportData);

        reportSetter(false)
    }

    return (
        <div style={modalStyle}>
            <h3 style={h3Style}>Thanks for letting us improve.</h3>
            <h3 style={h3Style}>What seems to be the problem?</h3>
            <form id='bug-report-form' onSubmit={e => e.preventDefault()} style={modalFormStyle}>
                <label style={formLabelStyle}>
                    <input type="checkbox" name="text" style={formInputStyle}/>
                    Text is unreadable.
                </label>
                <label style={formLabelStyle}>
                    <input type="checkbox" name="figure" style={formInputStyle}/>
                    Figures are missing.
                </label>
                <label style={formLabelStyle}>
                    <input type="checkbox" name="button" style={formInputStyle}/>
                    Buttons are unresponsive.
                </label>
                <label style={formLabelStyle}>
                    <input type="checkbox" name="other" style={formInputStyle}/>
                    Other issue
                </label>
                
                <label style={textLabelStyle}>
                    Can you please be specific?
                    <textarea name="details" cols="15" rows="5" style={formInputStyle}></textarea>
                </label>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%'
                }}>
                    <Button
                        label={'Confirm'}
                        bgColor={'#EDF058'}
                        navStyle={true}
                        onClickFn={submitModal}
                    />
                    <Button
                        label={'Cancel'}
                        bgColor={'#eee'}
                        navStyle={true}
                        onClickFn={cancelModal}
                    />
                </div>
                
            </form>
        </div>
    )
}

function sendEmail(obj) {
    emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        obj,
        process.env.REACT_APP_PUBLIC_KEY)
    .then((result) => {
        // show the user a success message
    }, (error) => {
        // show the user an error
        logAppError('bug report email not sent.')
        logAppError(error)
    });
}

/**
 * https://dashboard.emailjs.com/admin
 

{{target}}

{{timestamp}}

Issues:

{{issues}}

Details:

{{details}}
 */