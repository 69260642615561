import { useNavigate } from "react-router-dom"
import Button from "./Button";

const errorScreenStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: '100vh',
    fontFamily: 'var(--font-text)',
    textAlign: 'center',
    backgroundColor: '#121212',
    color: '#eee'
}

/**
 * Error codes and messages
 */
function getErrorMsg(code){
    switch(code){
        case 404: return '404 | Not Found.'
        case 500: return '500 | Internal Server Error.'
        case 503: return '503 | Service Unavailable.'
    }
}
function getResponse(code){
    switch(code){
        case 404: return 'The page you requested does not exist.'
        case 500: return 'We are fixing it ASAP.'
        case 503: return 'We are fixing it ASAP.'
    }
}

export default function Error({ code }){

    const navigate = useNavigate();

    return (
        <div
            className="_Error"
            style={errorScreenStyle}
        >
            <div>
                <h3>Oops, there seems to be an error.</h3>
                <h3>{getResponse(code)}</h3>
            </div>
            <h1>{getErrorMsg(code)}</h1>
            <Button
                label={<div onClick={() => navigate('/')}>
                            Back to Menu
                    </div>}
                bgColor={'#58F0F0'}
            />
        </div>
    )
    
}