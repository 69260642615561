import { logApiState } from "../Logger";

/**
 * For general purpose GET queries.
 */
export const makeGetQuery = async (query, token) => {

    return await fetch(query, {
        method: 'GET',
        headers: {
            "Authorization": token,
             'Access-Control-Allow-Origin': '*'
        }
    })
      .then(response => {
        logApiState('FETCH SUCCESS FOR: ' + query)
        return response.json()
    })
      .catch(error => {
            logApiState('FETCHING: ' + query + ' UNSUCCESSFUL. ERROR -')
            console.log(error)
    });
}