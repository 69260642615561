import Navbar from "../Utils/Navbar";
import '../../styles/QuizGenerator.css';


import { useNavigate } from "react-router-dom";
import Button from "../Utils/Button";
import { useEffect, useState } from "react";
import { generateQueryString } from "../../apiConnectors/FetchQuestionData";
import { getSectionAndExamData } from "../../apiConnectors/FetchDefaultData";
import QuizGenSidebar from "../SubComponents/QuizGenerator/QuizGenSidebar";
import { logAppError } from "../../Logger";
import { CHECKED_COLOR, FEATURED_QUESTION_NUM } from "../../apiConnectors/CONSTANTS";
import LoadingScreen from "../Utils/LoadingScreen";

/**
 * Notice: 'Exam' and 'Year' mean the same thing and are used interchangably.
 */

export default function QuizGenerator(){

    // An object with data for sections and exams.
    const [sectionsAndYears, setSectionsAndYears] = useState(null);

    const navigate = useNavigate(); // to redirect to Quiz.

    // Initializing sections and exam data.
    useEffect(()=>{

        getSectionAndExamData()
            .then(result => {
                // if empty, show error page.
                if(!result.sections || !result.exams){
                    throw new Error(' No section/exam ')
                }
                setSectionsAndYears(result);
            })  
            .catch(
                err => {
                    logAppError('[QuizGenerator.jsx] Cannot fetch section/exam data.')
                    logAppError(err)
                    navigate('/error/503')
                }
            )

        // fetch('testapi.com').then(res => setSectionsAndYears([]))

    }, []);

    /**
     * Change backgroundColor when user selects one of the sections/years.
     */
    const checked = e => {
        e.target.labels[0].style.backgroundColor =
            e.target.checked ? CHECKED_COLOR : 'transparent';
    }

    /**
     * Select ALL or NONE of sections or years.
     */
    const checkElements = (e, queryToSelect) => {
        if(e.target.textContent === 'All'){
            document.querySelectorAll(queryToSelect).forEach(box => {
                    box.checked = true;
                    box.labels[0].style.backgroundColor = CHECKED_COLOR;
            })
        } else {
            document.querySelectorAll(queryToSelect).forEach(box =>{
                    box.checked = false;
                    box.labels[0].style.backgroundColor = 'transparent';
            })
        }
    }

    return (
        
        <>
        {sectionsAndYears
            ?
            <div className="_QuizGenerator">

            <Navbar type={'quiz'} reportTarget={'quizgen'}/>
            <div className="quizgen-content">

                <QuizGenSidebar 
                    featuredQuestionNum={ FEATURED_QUESTION_NUM }
                    sectionsAndYears={ sectionsAndYears }
                    navigate={ navigate } />

                <main data-testId='quizGenMain'>
                    {/* ALL | NONE filter */}
                    <div className="quizgen-filter">
                        <div>
                            Sections:
                            <button onClick={(e) => checkElements(e, '.section-checkbox')}>All</button>
                            <button onClick={(e) => checkElements(e, '.section-checkbox')}>None</button>
                        </div>
                        <div>
                            Years:
                            <button onClick={(e) => checkElements(e, '.year-checkbox')}>All</button>
                            <button onClick={(e) => checkElements(e, '.year-checkbox')}>None</button>
                        </div>
                    </div>
                    <hr className="quizgen-softline"/>

                    {/* Sections and Years to select from */}
                    <form action="" onSubmit={(e) => {submitQuizGenForm(e, navigate)}}>
                        <section className="quizgen-sections">
                        {
                            sectionsAndYears && sectionsAndYears.sections.map((section) =>
                                <label className='quizgen-section' key={section.id}>
                                    {section.name}
                                    <input type="checkbox" className="section-checkbox" value={'S_'+section.id} onChange={checked}/>
                                </label>
                            )
                        }
                        </section>
                        <hr className="quizgen-softline"/>
                        <section className="quizgen-years">
                        {
                            sectionsAndYears && sectionsAndYears.exams.map((exam) =>
                                <label className='quizgen-year' key={exam.id}>
                                    {exam.year + ' ' + exam.month}
                                    <input className="year-checkbox" type="checkbox" value={'Y_'+exam.id} onChange={checked}/>
                                </label>
                            )
                        }
                        </section>
                        <hr className="quizgen-softline"/>

                        {/* div with GENERATE QUESTIONS button */}
                        <div className="quizgen-btnwrapper">
                            <label>
                                Total Questions:
                                <input id="quizgen-totalnum" type="number" min='1' max='999' defaultValue={FEATURED_QUESTION_NUM}/>
                            </label>
                            <p id="quizgen-error" style={{display: 'none'}}>
                                Error: Please select at least one section and at least one exam.
                            </p>
                            <Button
                                label={'Generate Questions'}
                                bgColor={'#AEFFFF'}
                            />
                        </div>
                    </form>
                </main>
            </div>
        </div>
        : <LoadingScreen/>}
        </>
    )
}

/**
 * When user clicks GENERATE QUESTIONS,
 *  1.  prevent default refresh
 *  2.  format request data
 *  3.  if no section/year is selected, show error.
 *  4.  if request data is valid,Redirect to Quiz with query string.
 *  5.  if success: send data to Quiz; else: show error page.
 */
function submitQuizGenForm (e, navigate) {

    /* 1.  prevent default refresh */
    e.preventDefault();

    /* 2.  format request data */
    let reqData = {
        type: 'custom',
        sections:[],
        years:[],
        number: document.getElementById('quizgen-totalnum').value
    };
    buildReqData(e.target, reqData);

    /* 3.  if no section/year is selected, show error and return. */
    let errorTag;
    if(reqData.sections.length === 0 || reqData.years.length === 0){
        errorTag = document.getElementById('quizgen-error');
        errorTag.style.display = 'block';
        setTimeout(() => errorTag.style.display = 'none', 3000);
        return;
    }
    // remove error tag if user clicks generate questions correctly earlier than error's disappear.
    if(errorTag) errorTag.style.display='none';

    /* 4. Redirect to Quiz with query string. */
    let queryString = generateQueryString(reqData);
    if(queryString){
        queryString = queryString.replaceAll('/','&');
        navigate('/quiz/'+queryString)
    } else {
        logAppError('[QuizGenerator.jsx]: queryString is ' + queryString + ' when reqData is: ')
        logAppError(reqData)
    }
}

/**
 * Recursive function that collects all input values.
 */
function buildReqData (node, collection){
    if(node.nodeName === 'INPUT' && node.type === 'checkbox' && node.checked){
        if(node.value.substring(0,2) === 'Y_'){
            collection.years.push(node.value.substring(2));
        } else {
            collection.sections.push(node.value.substring(2));
        }
    }
    if(node.hasChildNodes){
        [...node.children].forEach(e => buildReqData(e, collection))
    }
}

/*

SPARE: to add "Features for Members" section.


import spacedRImg from '../../assets/images/spacedRImg.png';
import progressTImg from '../../assets/images/progressTImg.png';

    <div>
        <h2>Features for Members</h2>
        <div className="feature-title">
            <img src={spacedRImg} alt="" />
            <h3>Spaced Repetition</h3>
        </div>
        <p>We will prepare a daily review question set for you, based on your current activity.</p>

        <div className="feature-title">
            <img src={progressTImg} alt="" />
            <h3>Progress Tracking</h3>
        </div>
        <p>Your current progress on the textbook and for each chapter will be recorded instantly.</p>
    </div>
    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
        <Button
            label={<Link to='/signin'>Join For Free</Link>}
            bgColor={'#FBFF4E'}
        />
    </div>
*/