
const basicStyle = {
    border: '3px solid #000',
    boxShadow: '5px 5px 0 #000',
    borderRadius: '5px',
    fontSize: '18px',
    fontFamily: 'var(--font-btn)',
    fontWeight: '700',
    textTransform: 'uppercase',
}

const normalBtnStyle = {
    padding: '0.2rem 1.5rem',
}

const navBtnStyle = {
    marginLeft: '20px'
}

const bigNavBtnStyle = {
    fontSize: '16px',
    marginLeft: '30px',
    padding: '5px 10px'
}

export default function Button ({
    label, bgColor, navStyle, bigNavStyle, extraClass, onClickFn
}) {
    
    let btnStyle;
    if(navStyle) btnStyle = navBtnStyle;
    else if (bigNavStyle) btnStyle = bigNavBtnStyle;
    else btnStyle = normalBtnStyle;

    let btnClassName ='btn-component ' + (extraClass ? extraClass : '');

    return (
        <button
            style={{
                backgroundColor: bgColor,
                ...basicStyle,
                ...btnStyle
            }}
            className={btnClassName}
            onClick={onClickFn ? onClickFn : null}
        >
            {label}
        </button>
    )
}