import '../../../styles/Quiz.css'
import Button from '../../Utils/Button';

export default function ResultScreen ({ data, reviewFn, menuFn }) {
    
    // score answers for correct/incorrect and total numbers.
    const results = scoreAnswers(data);
    const correctness = (results.overall.correct/results.overall.total);
    
    return (
        <div className="_ResultScreen">
            <main>
                {/* Graph (Circle) */}
                <section className="result-graph" data-testId="circle-graph"
                    style={{
                        '--degree-num': `${correctness*360}`,
                        '--degree-deg': `${correctness*360}deg`
                    }}    
                >
                    <div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div>
                        <p className='result-num'>{results.overall.correct}/{results.overall.total}</p>
                        <p className='result-percent'>
                            {(correctness*100).toFixed(0)}%
                        </p>
                    </div>
                </section>

                <ul className="result-bars">
                    {
                        Object.keys(results).slice(1).map(
                            (sectionKey, i) => {
                                let section = results[sectionKey];
                                return (
                                    <li key={i}>
                                        <h5>{sectionKey}</h5>
                                        <div className="quiz-bar">
                                            <div style={{
                                                width: `calc((
                                                    ${section.correct/section.total}
                                                )*100%)`
                                                }} data-testId={i}></div>
                                        </div>
                                        <p>{section.correct}/{section.total}</p>
                                    </li>
                                )
                            }
                        )
                    }
                </ul>
            </main>
            <div className="btn-wrapper">
                <Button
                    label={'Back to Menu'}
                    bgColor={'#eee'}
                    onClickFn={menuFn}
                />
                <Button
                    label={'Start Reviewing'}
                    bgColor={'#eee'}
                    onClickFn={reviewFn}
                />
            </div>
            
        </div>
    )
}

/**
 * Returns a 'results' object to build stats.
 * Also mutates the data parameter by adding 'star' entry in each question.
 */
function scoreAnswers(data){

    let results = {
        overall: {
            total: data.length,
            correct: 0
        }
    };

    let sectionKey;

    data.forEach(q => {

        // add section, add to total.
        sectionKey = q.section.name;
        if(!results[sectionKey]){
            results[sectionKey] = {total:0, correct:0}
        }
        results[sectionKey]['total']++;

        // add to correct number.
        if(q.user_answer === q.correctAns){
            // add to overall correct number.
            results.overall.correct++;
            // add to section.
            results[sectionKey]['correct']++;
            q.star = false;
            q.isCorrect = true;

        // if incorrect, star the question.    
        } else {
            q.star = true;
            q.isCorrect = false;
        }
    })
    return results;
}