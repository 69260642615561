import { useEffect, useRef } from "react";
import { logApiState, logAppError } from "../Logger";
import { ROOT_PATH } from "./CONSTANTS";
import { DEFAULT_CREDENTIALS } from "./CONSTANTS";

// routes
const signupPath = '/auth/v1/signup/user';
const loginPath = '/auth/v1/login';
const testPath = '/data/v1/section/get/1'

// Queries
const signinupParam = (credentials) => {
    return {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
             Accept : 'application/json',
             'Access-Control-Allow-Origin': '*',
             'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
             'Access-Control-Allow-Headers': 'Content-Type'
        },
        body: JSON.stringify(credentials),
    };
}

// Static variables
const jwtStorageKey = 'feprojectjwt'; // key for jwt in localStorage.

/**
 * The following two functions are for signing up and loging in.
 */
export const signup = async (credentials) => {
    logApiState('Signing up.');
    fetch(ROOT_PATH + signupPath, signinupParam(credentials))
        .then(async (response) =>{
            if(response.status === 200){
               logApiState('signup successful.')
            } else {
                logApiState('signup error.')
                logApiState('response: ' + response);
                throw new Error('cannot sign up.');
            }
    }   ).catch(err => err)
}

export const login = async (credentials) => {
    logApiState('Logging in.')
    logApiState('login params: ')
    logApiState(signinupParam(credentials));

    return await fetch(ROOT_PATH + loginPath, signinupParam(credentials))
        .then(async (response) =>{
            if(response.status === 403){    // account haven't created in the database.
                logAppError('no account in database yet.')
                throw new Error ('account not created in the database yet.')
            } else if (response.status === 200) {   // login successful.
                const txt = response.text();
                logApiState('login successful.')
                logApiState(txt)
                return txt;
            } else {
                logApiState('error in connection. Response -')
                logApiState(response)
            }
        })
        .then(rawJson => {
            // rawJson is in the format {json: "Bear <token>"}, so it is parsed.
            const token = (JSON.parse(rawJson)).json;
            logApiState('loggedin jwt:')
            localStorage.setItem(jwtStorageKey, token);
            return token;
        })
        // .then(obj => {
        //     const token = obj.json();

        //     logApiState()
        //     return token
        // })
        .catch(err => {
            logAppError('login error: ')
            return err
        });
}

/**
 * Returns valid JWT token.
 * To get valid token, it:
 *      -   Looks into LocalStorage for the value of jwtStorageKey;
 *      -   Check expiration for validity;
 *      -   If valid, return token;
 *      -   If invalid, try to login; if login fails, try signing up;
 */

export const getJWTToken = async () => {

    // return Promise.resolve('Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdXRob3JpdGllcyI6W3siYXV0aG9yaXR5IjoiUkVBRF9DT1JFX0RBVEEifSx7ImF1dGhvcml0eSI6IlJPTEVfR1VFU1QifV0sInN1YiI6ImFub25AZGVtby5mZSIsImlhdCI6MTY3MzMzNzg1MywiZXhwIjoxNjczMzczODUzfQ.pyzL23yvp0E4dQiomYbgpAUgEFfpZvNGmOuvlbNGJi4')

    let jwt = localStorage.getItem(jwtStorageKey);

    if(jwt){
        try{
            const { exp }  = JSON.parse(window.atob(jwt.split('.')[1]));
            if (exp && Date.now() < exp * 1000) {
                logApiState('jwt still valid. returning jwt.')
                return jwt;
            }
        } catch { console.log("can't parse"); /* if jwt token cannot be parsed, just skip it. */}
    } 

    logApiState('jwt not valid. Logging In again with Default Params.');
    try {
        let newJwt = await login(DEFAULT_CREDENTIALS);
        return newJwt;
    } catch (error) {
        logApiState('login/signup error: ' + error)
    }
}

/**
 * 
const testJwt = async (token) => {
    logApiState('Testing jwt.')
    return await fetch(ROOT_PATH + testPath, {
        method: 'GET',
        headers: {
            "Authorization": token
        }
    })
        .then(async (response) =>{
            if(response.status !== 200){
                return await login();
            } else {
                return token;
            }
        }).catch(err => {
            console.error(err)
            throw new Error('unable to connect for testingJWT.');
        })
}
 */