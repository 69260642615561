const logger = msg => {/*console.log(msg)*/}

export const logNormal = text => {
    logger('GENERAL:: ' + text);
}

export const logApiState = text => {
    logger('API:: ' + text);
}

export const logAppError = text => {
    logger('ERROR:: ' + text);
}

export const logAppTest = data => {
    console.log('TEST:')
    console.log(data);
}

export const logBugReport = data => {
    logger('BUG REPORT STARTS')
    logger(data)
    logger('BUG REPORT ENDS')
}