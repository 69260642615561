
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/RouteComponents/Home';
import About from './components/SubComponents/Home/About';
import SignupPage from './components/SubComponents/Home/SignupPage';
import Dashboard from './components/RouteComponents/Dashboard';
import Quiz from './components/RouteComponents/Quiz';
import QuizGenerator from './components/RouteComponents/QuizGenerator';
import Error from './components/Utils/Error';
import AppContextProvider from './AppContextProvider';
import LoadingScreen from './components/Utils/LoadingScreen';
import Tmp from './components/Utils/Tmp';
import ReactGA from "react-ga4";


export default function App() {


  return (
    <AppContextProvider>
      <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<Home />}
            />
            <Route
              path="/home"
              element={<Home />}
            />
            <Route
              path="/about"
              element={<About />}
            />
            <Route
              path="/signin"
              element={<SignupPage signin={true}/>}
            />
            <Route
              path="/signup"
              element={<SignupPage signin={false}/>}
            />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/quizgen" element={<QuizGenerator />} />

            <Route path="/quiz" element={<Quiz/>}>
              <Route path=':query' element={<Quiz/>}></Route>
            </Route>

            <Route path='/tmp' element={<Tmp/>} />
            
            <Route path="/error/500" element={<Error code={500}/>} />
            <Route path="/error/503" element={<Error code={503}/>} />
            <Route path="*" element={<Error code={404}/>} />

          </Routes>
        </BrowserRouter>
    </AppContextProvider>
  );
}