import chevronImg from '../../../assets/images/chevronImg.png';
import arrowImg from '../../../assets/images/arrowImg.png';
import { useNavigate } from 'react-router-dom';
import { generateQueryString } from '../../../apiConnectors/FetchQuestionData';

export default function QuizGenSidebar ({ featuredQuestionNum, sectionsAndYears, navigate}) {

    /**
 * When user clicks START on one of the featured questions,
 *  1.  build request data.
 *  2.  if request data is valid,Redirect to Quiz with query string.
 *  3.  if success: send data to Quiz; else: show error page.
 */
function takeFeaturedQuiz (theme, e) {

    /* 1. build request data. */
    let reqData;

    if(theme === 'Y'){  // for the year-based option.
        
        reqData = {
            type: 'featured-years',
            year:  e.target.parentNode.children[0].value,
        }

    } else {            // for the section-based option.

        let sectionParam = e.target.parentNode.children[0].value;
        let sections;

        if(sectionParam === 'all'){ // for all sections.
            sections = sectionsAndYears.sections.map(s => s.id);
        } else if (sectionParam === 'vol_01'){  // for all sections from volume 1.
            sections = sectionsAndYears.sections.filter(s => s.volume === 'VOLUME1').map(s => s.id)
        } else if (sectionParam === 'vol_02'){  // for all sections from volume 2.
            sections = sectionsAndYears.sections.filter(s => s.volume === 'VOLUME2').map(s => s.id)
        } else {    // one section only.
            sections = [sectionParam];
        }

        reqData = {
            type: 'custom',
            sections: sections,
            years:sectionsAndYears.exams.map(year => year.id),
            number: featuredQuestionNum
        };

    }
    
    /* 2. Redirect to Quiz with query string. */
    let queryString = generateQueryString(reqData).replaceAll('/', '&')
    navigate('/quiz/'+queryString)
}

    return (
        <aside className="_QuizGenSidebar">
            <h2>Featured Question Sets</h2>
            <p>Try out one of the following question sets to test your knowledge.</p>
            <hr className="quizgen-softline"/>
            <label className='quizgen-featured-section'>
                {featuredQuestionNum}
                <select>
                    <option value='all'>Random</option>
                    <option value='vol_01'>random Vol.1</option>
                    <option value='vol_02'>random Vol.2</option>
                    {sectionsAndYears && sectionsAndYears.sections.map((section) => 
                        <option value={section.id} key={section.id}>{section.name}</option>
                    )}
                </select>
                questions
                <button onClick={(e) => takeFeaturedQuiz('S', e)}>Start</button>
            </label>
            <hr className="quizgen-softline"/>
            <label className='quizgen-featured-year'>
                <select>
                    {sectionsAndYears && sectionsAndYears.exams.map((exam) => 
                        <option value={exam.id} key={exam.id}>{exam.year + ' ' + exam.month}</option>
                    )}
                </select>
                questions
                <button onClick={(e) => takeFeaturedQuiz('Y', e)}>Start</button>
            </label>
            <hr className="quizgen-softline"/>
            <p>Or customize your own question set.</p>
            <a href="#quizgen-custom" id="quizgen-custom">
                <img className="downChevron-img" src={chevronImg} alt="To Custom Questions" />
                <img className="arrow-img" src={arrowImg} alt="" />
            </a>
        </aside>
    )
}