import Button from "../Button"
import { Link } from "react-router-dom"

export default function ComingSoon(){
    return (
        <div style={{
            position: 'absolute',
            top:'0',
            width: '100vw',
            height: '100%',
            backgroundColor: '#333E',
            zIndex: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                padding: '15px 30px',
                fontFamily: 'var(--font-text)',
                backgroundColor: '#fff',
                border: '3px solid #000',
                boxShadow: '10px 10px 0 #555',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <h3 style={{
                    textAlign: 'center',
                    lineHeight: '35px'
                }}>
                    Membership feature <br/> will be released soon.
                </h3>
                
                <Button
                        label={<Link className="practice-btn" to='/quizgen'>Practice</Link>}
                        bgColor={'#EDF058'}
                    />
            </div>
        </div>
    )
}