import { useState } from "react";
import { logApiState } from "../Logger";
import { getJWTToken } from "./ConnectWithApi";
import { ROOT_PATH } from "./CONSTANTS";
import { makeGetQuery } from "./Utils";

const allSectionsPath = '/data/v1/section/getAll';
const allExamsPath = '/data/v1/exam/getAll';

// As sectionAndExamData doesn't really change, it is cached.
let sectionAndExamData = null;

/**
 * Fetch arrays of allSections and allExams
 */
export const getSectionAndExamData = async () => {

    if(sectionAndExamData) return sectionAndExamData;

    logApiState('data not cached. redoing request.')

    let token = await getJWTToken();
    let sections = await makeGetQuery(ROOT_PATH + allSectionsPath, token);
    let exams = await makeGetQuery(ROOT_PATH + allExamsPath, token);

    sectionAndExamData = {sections, exams};

    return { sections, exams }
}

export const mockReturn = () => 10;